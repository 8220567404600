import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from "#src/stores/state-wrapper.js"

import { useStepSecondaryAddresseeStore } from '#src/stores/step-secondary-addressee.js';
import { useStepExistingCoverageStore } from '#src/stores/step-existing-coverage.js';
import { useInsuredStore } from '#src/stores/insured.js';

import { REQS } from '#src/enumerations/data-requirements.js';
import { HttpDoctorVisitsService } from '#src/services/http-doctor-visits.service.js';
import { doctorVisitKeygen, useDoctorVisit } from '#src/stores/doctor-visit.js';
import { HttpIndividualsService } from '#src/services/http-individuals.service.js';

export const useStepPhysicianStore = (pinia, hot) => {
  const individualsService = new HttpIndividualsService(pinia);
  const httpDoctorVisitsService = new HttpDoctorVisitsService(pinia);
  return defineStore('step-physician', {
    state: () => ({
      inactive: false,
      requiredFields: {
        humanAPI: false,
        doctorVisits: false,
      },
    }),
    getters: {
      step: () => steps.PHYSICIAN,
      routeName: (s) => stepToRouteName[s.step],
      next() {
        const secondaryAddressee = useStepSecondaryAddresseeStore(pinia);
        if (secondaryAddressee.inactive) return secondaryAddressee.next;
        return secondaryAddressee.step;
      },
      previous: () => {
        const existing = useStepExistingCoverageStore(pinia);
        if (existing.inactive) return existing.previous;
        return existing.step;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.requiredFields.humanAPI = srq[REQS.HUMAN_API];
        this.requiredFields.doctorVisits = srq[REQS.DOCTOR_VISITS];

        this.inactive = Object.values(this.requiredFields).every((v) => v === false);
      },
      humanSession(insuredType) {
        const insured = useInsuredStore(insuredType);
        return individualsService.humanSession(insured.id);
      },
      updateMedicalSources(insuredType) {
        const insured = useInsuredStore(insuredType);
        const currentConnections = [];
        const requestedSources = [];
        insured.medical_sources.forEach((value) => {
          if (value.name) currentConnections.push(value);
          else requestedSources.push(value);
        });
        return individualsService.updateMedicalSources(
          insured.id,
          requestedSources,
          currentConnections,
        );
      },
      async addDoctorVisit(insuredType) {
        const insured = useInsuredStore(insuredType);
        const id = await httpDoctorVisitsService.createDoctorVisit({
          insured_id: insured.id,
          insured_type: 'Individual',
        });

        const key = doctorVisitKeygen(id);
        insured.doctor_visits.push(key);

        const doctorVisit = useDoctorVisit(key, pinia);
        doctorVisit.insuredType = insuredType;
        doctorVisit.id = id;
        return key;
      },
      async removeDoctorVisit(insuredType, doctorVisitId) {
        const insured = useInsuredStore(insuredType);
        const doctorVisit = useDoctorVisit(doctorVisitId, pinia);
        await httpDoctorVisitsService.deleteDoctorVisit(doctorVisitId);
        insured.doctor_visits.splice(insured.doctor_visits.indexOf(doctorVisitId), 1);
        doctorVisit.$dispose();
      },
    },
  })(pinia, hot);
};
