import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from "#src/stores/state-wrapper.js"
import { CONTINGENT_BENEFICIARY } from '#src/structures/Role.js';
import { REQS } from '#src/enumerations/data-requirements.js';

import { getPartyStore, useEappStore } from '#src/stores/electronic-application.js';
import { usePrimaryInsuredStore } from '#src/stores/insured.js';
import { useStepBeneficiaryPrimaryStore } from '#src/stores/step-beneficiary-primary.js';
import { useStepPersonalStore } from '#src/stores/step-personal.js';

export const useStepBeneficiaryContingentStore = (pinia, hot) =>
  defineStore('step-beneficiary-contingent', {
    state: () => ({ inactive: false }),
    getters: {
      step: () => steps.CONTINGENT_BENEFICIARY,
      routeName: (s) => stepToRouteName[s.step],
      requiredFields: () => {
        const primary = useStepBeneficiaryPrimaryStore(pinia);
        return primary.requiredFields;
      },
      next: () => {
        const personal = useStepPersonalStore(pinia);
        if (personal.inactive) return personal.next;
        return personal.step;
      },
      previous: () => {
        const primary = useStepBeneficiaryPrimaryStore(pinia);
        if (primary.inactive) return primary.previous;
        return primary.step;
      },
      skippable: () => {
        const eApp = useEappStore(pinia);
        if (eApp.isDualInsured) return true;
        const insured = usePrimaryInsuredStore(pinia);

        if (!eApp.beneficiariesByInsuredId[insured.id]?.length) return true;

        const contingentBeneficiaries = eApp.beneficiariesByInsuredId[insured.id].filter(
          (v) => getPartyStore(v, pinia).roles[CONTINGENT_BENEFICIARY],
        );

        return !contingentBeneficiaries.length;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.inactive = !srq[REQS.CONTINGENT_BENEFICIARY];
      },
    },
  })(pinia, hot);
