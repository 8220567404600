import { steps, stepToRouteName } from '#src/enumerations/step-enumeration.js';
import { defineStore } from "#src/stores/state-wrapper.js"
import { useEappStore } from '#src/stores/electronic-application.js';
import { useStepNameStore } from '#src/stores/step-name.js';
import { REQS } from '#src/enumerations/data-requirements.js';
import { useReferralForm } from '#src/components/steps/referral/referral-form.composable.js';

export const useStepReferralStore = (pinia, hot) =>
  defineStore('step-referral', {
    state: () => ({ inactive: false }),
    getters: {
      hideNoLicenseBanner: () => true,
      step: () => steps.REFERRAL,
      routeName: (s) => stepToRouteName[s.step],
      next() {
        const name = useStepNameStore(pinia);
        return name.step;
      },
      previous: () => null,
      controlsNext() {
        const eApp = useEappStore(pinia);
        return !eApp.refer && !eApp.id;
      },
    },
    actions: {
      setStepRequirements(reqs) {
        this.inactive = !reqs[REQS.REFERRAL];
      },
      generateForm(module, args) {
        return useReferralForm(pinia, module, args)
      }
    },
  })(pinia, hot);
